import Home from "./pages/Home";

import { Routes, Route } from "react-router-dom";
import Thanks from "./components/Thanks";

// update
function App() {
  return (
    <>
      <Routes>
        <Route path="/" exact element={<Home />} />
        <Route path="/thanks" element={<Thanks />} />
      </Routes>
    </>
  );
}

export default App;

