import { React, Fragment } from "react";
import { Disclosure, Menu, Transition } from "@headlessui/react";
import { Bars3Icon, BellIcon, XMarkIcon } from "@heroicons/react/24/outline";
import logo from "../assets/images/switchthreelogo.gif";
import "../App.css";
import { CiSearch } from "react-icons/ci";
const navigation = [
  {
    name: "Home",
    href: "#",
    current: false,
    id: "headerid",
    scrolldown: 0,
  },
  { name: "Contact", href: "#advantage", current: false, id: "advantageid" },
  { name: "For Brand", href: "#hotoffers", current: false, id: "hotofferid" },
  {
    name: "For Creators",
    href: "#locations",
    current: false,
    id: "locationid",
  },
];
const navigationtwo = [
  {
    name: "__________________________",
    href: "#",
    current: false,
    id: "headerid",
    scrolldown: 0,
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

// comment here

export default function NavBar() {
  return (
    <Disclosure as="nav" className="bg-white drop-shadow-lg">
      {({ open }) => (
        <>
          <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
            <div className="relative flex h-16 items-center justify-start">
              <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                {/* Mobile menu button*/}
                <Disclosure.Button className="relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                  <span className="absolute -inset-0.5" />
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
              <div className="flex flex-shrink-0 items-center">
                <img className="h-24 w-auto" src={logo} alt="Your Company" />
                <div className="  space-x-4 hidden md:flex">
                  {navigation.map((item) => (
                    <a
                      key={item.name}
                      href={item.href}
                      className={
                        `   font-semibold leading-10 opacity-80
                        text-black md:text-xs font-sans  
                        rounded-md px-3 py-2 text-sm `
                      }
                      aria-current={item.current ? "page" : undefined}
                    >
                      {item.name}
                    </a>
                  ))}
                </div>
              </div>
              <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-center"></div>
              <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                <div className="hidden sm:ml-6 sm:block">
                  <div className="flex  space-x-4">
                    {/* {navigationtwo.map((item) => ( */}
                    <div
                      // key={item.name}
                      // href={item.href}
                      className={` 
                             swith-three-desc_gradient  text-[0.9rem] font-gotham font-bold
                          rounded-md px-3 py-2 text-sm font-medium`}
                    >
                      ____________________
                      <CiSearch className="w-6 h-6" />
                    </div>
                    {/* // ))} */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </Disclosure>
  );
}
