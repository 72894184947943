import React from "react";
import "../App.css";
import { ServicesDepthData } from "../data";
import gradient from "../assets/images/services/gradient.png";

export default function ServicesDepth() {
  return (
    <div className="p-8 ">
      {ServicesDepthData.map((data) => (
        <div className={`Services-background rounded-2xl shadow-2xl md:pb-4 `}>
          <div
            // here logic
            //  className={`flex md:flex-row flex-col ${
            //   data.id % 2 === 0 ? "flex-row-reverse" : ""
            // }`}
            key={data.id}
          >
            <div className="py-8 bg-white">
              <div className="container m-auto px-6 text-gray-600 md:px-12 xl:px-6">
                <div
                  className={`space-y-6 md:space-y-0 md:flex ${
                    data.id % 2 === 0 ? "flex-row-reverse" : null
                  }  md:gap-6 lg:items-center lg:gap-12`}
                >
                  <div className="md:w-5/12 lg:w-5/12 rounded">
                    <img src={data.img} alt="image" loading="lazy" />
                  </div>
                  <div className="md:7/12 lg:w-6/12">
                    <h2 className="text-xl text-gray-900 font-bold md:text-2xl">
                      {data.heading}
                    </h2>
                    <p className="mt-6 text-gray-600 text-base">
                    {data.desc}
                    </p>
                    {/* <p className="mt-4 text-gray-600  text-base">
                      {" "}
                      Nobis minus voluptatibus pariatur dignissimos libero
                      quaerat iure expedita at? Asperiores nemo possimus
                      nesciunt dicta veniam aspernatur quam mollitia.
                    </p> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}
