import React from "react";
import "../App.css";
import { ServicesCompData } from "../data";
import gradient from "../assets/images/services/gradient.png";

export default function Services() {
  return (
    <div className="p-8 ">
      <div className=" Services-background rounded-2xl shadow-2xl md:pb-12">
        <div className="text-center md:pt-16 md:pb-12 ">
          <h1 className="md:text-4xl md:pb-5  text-2xl font-bold text-black border-b-[0.5px]  border-b-black">
            Our Services
          </h1>
        </div>

        <div
          style={{
            backgroundImage: `url(${gradient})`,
            backgroundPosition: "center",
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
          }}
        >
          {ServicesCompData.map((data) => (
            <div className="flex md:flex-row flex-col" key={data.id}>
              <div className="flex justify-center  md:mt-9 md:w-36  md:h-12 md:ml-4 ">
                <div className="w-16">
                  <img src={data.icon_first} alt="" />
                </div>
              </div>
              <div className="p-4 md:mx-2 md:w-[35rem] ">
                <h2 className="text-xl md:font-bold md:text-left text-center font-poppins">
                  {data.heading_first}
                </h2>
                <p className="text-base p-1 text-slate-600 md:text-left text-center ">
                  {" "}
                  {data.desc_first}
                </p>
                <p></p>
              </div>
              <div className="flex justify-center md:mt-9 md:w-36  md:h-12 md:ml-4 ">
                <div className="w-16">
                  <img src={data.icon_second} alt="" />
                </div>
              </div>
              <div className="p-4 md:mx-2 md:w-[35rem] ">
                <h2 className="text-xl md:font-bold font-poppins md:text-left text-center">
                  {data.heading_second}
                </h2>
                <p className="text-base p-1 text-slate-600 md:text-left text-center">
                  {" "}
                  {data.desc_second}
                </p>
                <p></p>
              </div>
            </div>
          ))}
          <div className="flex justify-center md:mt-10">
            <a href="tel:9717443746">
          <button class="mt-6 text-black md:text-base border-2 border-black px-6 py-1 rounded-md font-normal  ">
          Want to discuss Let's Schedule a Call
          </button>
          </a>
          </div>
        </div>
      </div>
    </div>
  );
}
