import React from 'react';
import '../App.css';
import NavBar from '../components/NavBar';
import HeaderTitle from '../components/HeaderTitle';
import { Whoweare } from '../components/Whoweare';
import { Milestone } from '../components/Milestone';
import LogoSlider from '../components/LogoSlider';
import Footer from '../components/Footer';
import Services from '../components/Services';
import ServicesDepth from '../components/ServicesDepth';

export default function Home() {
  return (
    <>
      <NavBar />
      <HeaderTitle />
      <Services />
      <ServicesDepth/>
      <Whoweare />
      <Milestone />
      <LogoSlider />
      <Footer />
    </>
  )
}
