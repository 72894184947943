import firsticon from "./assets/images/services/first.png";
import secondicon from "./assets/images/services/second.png";
import thirdicon from "./assets/images/services/three.png";
import fourthicon from "./assets/images/services/fourth.png";
import fifthicon from "./assets/images/services/fifth.png";
import sixthicon from "./assets/images/services/sixthtwo.png";



// services depth here
import contentcreation from "../src/assets/images/servicesDepth/contentcreation.gif";
import socialmedia from "../src/assets/images/servicesDepth/first.gif";
import creativedesign from "../src/assets/images/servicesDepth/creativedesign.gif";
import videoads from "../src/assets/images/servicesDepth/videoads.gif";
import webdev from "../src/assets/images/servicesDepth/webdev.gif";
import strategicmarketing from "../src/assets/images/servicesDepth/strategicmarketing.gif";

export const ServicesCompData = [
  {
    id: 1,
    icon_first: firsticon,
    heading_first: `Content Creation`,
    desc_first: `We create content that reflects your business personality and values . From stratagic planning to captivating copywriting and stunning visuals , we ensures your brand’s message resonates with your audience.`,
    icon_second: secondicon,
    heading_second: `Social media management`,
    desc_second: `We are experts in social media , from creating and posting content, to running social media ads, we make your brand shine across the social platforms.`,
  },
  {
    id: 2,
    icon_first: thirdicon,
    heading_first: `Creative Design `,
    desc_first: `Transform your vision into stunning visuals. Our designs, from branding to marketing materials, captivate and resonate with your audience, in simple  we make your competitors jealous, and your audience adore you `,
    icon_second: fourthicon,
    heading_second: `Video ads `,
    desc_second: `This is the Age of videos from insta reels to yotube shorts , Grab attention and Drive engagement with our creative captivating video ads. We create compelling visuals that tell your brand story effectively. And improve you ROAS `,
  },
  {
    id: 3,
    icon_first: fifthicon,
    heading_first: `Web Development `,
    desc_first: `Is your business missing out on potential customers because you’re not online ? Don’t let competitors steal your Market share. We create compelling visuals that tell your brand story effectively. `,
    icon_second: sixthicon,
    heading_second: `Strategic Marketing`,
    desc_second: `Your strategic marketing partner. We crafts compelling narratives and targeted campaigns to amplify your brand's reach and drive meaningful engagement. Let's elevate your marketing together`,
  },
];

export const ServicesDepthData = [
  {
    id: 1,
    img: contentcreation,
    heading: "Content Creation",
    desc: `We create content that reflects your business personality and values . From stratagic planning to captivating copywriting and stunning visuals , we ensures your brand’s message resonates with your audience.We are experts in social media , from creating and posting content, to running social media ads, we make your brand shine across the social platforms.`,
  },
  {
    id: 2,
    img: socialmedia,
    heading: "Social media management",
    desc: `We are experts in social media , from creating and posting content, to running social media ads, we make your brand shine across the social platforms.`,
  },
  {
    id: 3,
    img: creativedesign,
    heading: "Creative Design",
    desc: `Transform your vision into stunning visuals. Our designs, from branding to marketing materials, captivate and resonate with your audience, in simple we make your competitors jealous, and your audience adore you`,
  },
  {
    id: 4,
    img: videoads,
    heading: "Video ads",
    desc: `This is the Age of videos from insta reels to yotube shorts , Grab attention and Drive engagement with our creative captivating video ads. We create compelling visuals that tell your brand story effectively. And improve you ROAS`,
  },
  {
    id: 5,
    img: webdev,
    heading: "Web Development",
    desc: `Is your business missing out on potential customers because you’re not online ? Don’t let competitors steal your Market share. We create compelling visuals that tell your brand story effectively.`,
  },
  {
    id: 6,
    img: strategicmarketing,
    heading: "Strategic Marketing",
    desc: `Your strategic marketing partner. We crafts compelling narratives and targeted campaigns to amplify your brand's reach and drive meaningful engagement. Let's elevate your marketing together`,
  },
];
